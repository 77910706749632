import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ServicesAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import Chimney3 from '../../Images/Chimney3.jpeg'
import bgnobgs from '../../Images/bgnobgs.png'
import AreaData from '..//ServicesPage/AreaData'

export default function ServicesAll() {
    const navigate = useNavigate()


    return (
        <div className="ServicesAll">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Chimney Services | Corona Chimney Sweep - Cleaning, Inspection & Repair</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Chimney Services | Corona Chimney Sweep - Cleaning, Inspection & Repair" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Chimney Services | Corona Chimney Sweep - Cleaning, Inspection & Repair" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="chimneysweepcorona.org/Reviews by our customers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the full range of professional chimney services offered by Corona Chimney Sweep. From cleaning and inspections to repairs and maintenance, we keep your chimney safe and efficient." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the full range of professional chimney services offered by Corona Chimney Sweep. From cleaning and inspections to repairs and maintenance, we keep your chimney safe and efficient." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Chimney Services | Corona Chimney Sweep - Cleaning, Inspection & Repair" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="ServicesAllTitle">
                <h1>Comprehensive Chimney Services in Corona, CA</h1>
                <h2>At Corona Chimney Sweep, we offer a full range of professional chimney services to keep your home safe, warm, and efficient. Whether you need a thorough chimney cleaning, a detailed inspection, or expert repairs, our skilled technicians are here to provide top-quality service. From routine maintenance to addressing complex issues, we ensure your chimney is in optimal condition. Explore our services to find the right solution for your home, and trust Corona Chimney Sweep to deliver the excellence and reliability you deserve.</h2>
            </div>
            <div className="ServicesAllLsit">
                {AreaData.map((A, index) =>
                    <a href={A.Path} className="Header1BoxMenuBox">
                        
                        <div className="ServicesAllBox">
                            <h2>{A.Title}</h2>
                            <h6>{A.TitleShortVery}</h6>
                            <h4>{A.Full}</h4>
                            <h5>EXPLORE THIS SERVICE</h5>
                        </div>
                    </a>
                )}
            </div>

            <NavigatorPath />

        </div>
    )
}

